import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';

function App() {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      console.log(window.scrollY);
    };
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  let center = window.innerWidth/2;
  console.log(center);
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{top:scrollY+window.innerHeight/8,width:window.innerWidth,height:window.innerHeight/2,position:'absolute'}}>Стася,</h1>
        <div className='ilik' style={{top:scrollY+window.innerHeight/4,width:window.innerWidth,height:window.innerHeight/2,position:'absolute'}}>
        <div className='ilik1' style={{top:'50%',width:'100%',height:'100%',background:'#121214',position:'absolute',alignItems:'center'}}>Я тебя люблю! ❤️❤️❤️</div>
        <div className='ilik1' style={{right:center+scrollY,width:'12.5%',height:'100%',background:'#29D9D5',borderRadius:'15%',position:'absolute'}}/>
        <div className='ilik2' style={{left:center+scrollY,width:'12.5%',height:'100%',background:'#29D9D5',borderRadius:'15%',position:'absolute'}}/>
        <div className='ilik2' style={{left:scrollY<400? center: center+scrollY-400,width:'12.5%',height:'100%',background:'#DFD8CB',borderRadius:'15%',position:'absolute'}}/>
        <div className='ilik2' style={{right:scrollY<400? center: center+scrollY-400,width:'12.5%',height:'100%',background:'#DFD8CB',borderRadius:'15%',position:'absolute'}}/>
        <div className='ilik2' style={{left:scrollY<800? center: center+scrollY-800,width:'12.5%',height:'100%',background:'#F5F3EA',borderRadius:'15%',position:'absolute'}}/>
        <div className='ilik2' style={{right:scrollY<800? center: center+scrollY-800,width:'12.5%',height:'100%',background:'#F5F3EA',borderRadius:'15%',position:'absolute'}}/>
        {/* 29D9D5 */}
        </div>

      </header>
    </div>
  );
}

export default App;
